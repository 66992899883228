import React, { memo } from 'react';
import CustomLink from '../common/CustomLink';
import { Link } from 'gatsby';
import GatsbyImage from './../common/GatsbyImage';
import { getModelUrl } from '../../utils/helpers';

const PromoImage = memo(({ image, link, title, sub_title, lifestyle_image, tag, button_text }) => {
  return (
    <div className="col-xl-3 col-lg-4 col-6 p-lg-3 p-0">
      <div className="promo">
        <figure className="promo__image">
          {image?.gatsbyImageData && (
            <Link to={link?.handle ? getModelUrl('buy', link?.handle) : '#'}>
              <GatsbyImage image={image?.gatsbyImageData} alt={image.alt} />

              {lifestyle_image?.gatsbyImageData && (
                <GatsbyImage
                  className="promo__image--hover"
                  image={lifestyle_image?.gatsbyImageData}
                  alt={lifestyle_image.alt}
                />
              )}
            </Link>
          )}

          {tag && (
            <span className="promo__tag">
              <span>{tag}</span>
            </span>
          )}
        </figure>
        <div className="promo__inner text-center">
          {title && <h3 className="promo__title text-left">{title}</h3>}
          {sub_title && <p className="promo__sub-title text-left">{sub_title}</p>}
          {button_text && (
            <button className="promo__btn btn-activeskin">
              <Link to={link?.handle ? getModelUrl('buy', link?.handle) : '#'}>{button_text}</Link>
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

export default PromoImage;
